import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SecHandService } from 'src/app/services/2hand/2hand.service';
import { AnimatedCount } from '../../dashboard/animated-count';

//import { BrandService } from 'src/app/services/brands/brand.service';

@Component({
    selector: 'pages-dash-2hand',
    templateUrl: './dash-2hand.component.html',
    styleUrls: ['dash-2hand.component.scss'],
})

export class Dash2HandComponent implements OnInit {

    loading: boolean = true;
    secondHandCostumers: any;
    id_brand: number;
    dashStruct: Dashstruct;
    dashStructAux: Dashstruct;
    barDataOptions: any;
    colors: any = [];
    barOptions: any;
    dataAux: any = [];
    animatedCount: AnimatedCount = new AnimatedCount();
    @Input() indexTab: number;
    isAnimated: boolean = false;
    @Input() currentTab2h:string;
    currentTab2hAux:string;


    constructor(
        private secondHandservice: SecHandService

    ) {
        this.dashStruct = {
            nCostumers: null,
            ncListings: null,
            nSales: null,
            aSales: null,
            tPListed: null,
            tBSProduct: null,
            sBCountry: null,
            asTime: {
                days:null,
                hours:null,
                minutes:null,
            },
            pBRPrice: null,
        }
        this.dashStructAux = {
            nCostumers: null,
            ncListings: null,
            nSales: null,
            aSales: null,
            tPListed: null,
            tBSProduct: null,
            sBCountry: null,
            asTime: {
                days:null,
                hours:null,
                minutes:null,
            },
            pBRPrice: null,
        }
    }


    async ngOnInit(): Promise<void> {
        await this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.currentTab2hAux=changes.currentTab2h.currentValue;
       
        if (changes.currentTab2h.currentValue == '2Hand' && this.loading == false && !this.isAnimated) {
            this.startAnimations();
        }
    }

    async init(): Promise<void> {


        this.dashStruct.nCostumers = await this.secondHandservice.get2HandDashboardRegisteredCustomers();
        this.dashStruct.ncListings = await this.secondHandservice.get2HandDashboardCurrentListings();
        this.dashStruct.nSales = await this.secondHandservice.get2HandDashboardNumberSales();
        this.dashStruct.aSales = await this.secondHandservice.get2HandDashboardTotalSales();
        this.dashStruct.tPListed = await this.secondHandservice.get2HandDashboardTopListedProducts();
        this.dashStruct.tBSProduct = await this.secondHandservice.get2HandDashboardTopBestSellingProducts();
        this.dashStruct.sBCountry = await this.secondHandservice.get2HandDashboardSalesByCountry();
        this.dashStruct.asTime = await this.secondHandservice.get2HandDashboardAverageSellingTime();
        this.dashStruct.pBRPrice = await this.secondHandservice.get2HandDashboardPBelowRetailPrice();
        //this.dashStruct = await this.secondHandservice.get2HandDashboardData(this.id_brand);
        await this.createBarStatusDate();
       
        this.loading = false;
        if(this.currentTab2hAux=='2Hand'){
            await this.startAnimations();
        }
    }
    async startAnimations() {

       const sub1 = (await this.animatedCount.createCurrentCount(this.dashStruct.nCostumers, 1000)).subscribe((currentValue) => {
            this.dashStructAux.nCostumers = currentValue;
        },
            null,
            () => {
                this.dashStructAux.nCostumers = this.dashStruct.nCostumers
                sub1.unsubscribe();
            }
        );
        const sub2 = (await this.animatedCount.createCurrentCount(this.dashStruct.ncListings, 1000)).subscribe((currentValue) => {
            this.dashStructAux.ncListings = currentValue;
        },
            null,
            () => {
                this.dashStructAux.ncListings = this.dashStruct.ncListings
                sub2.unsubscribe();
            }
        );
        const sub3 = (await this.animatedCount.createCurrentCount(this.dashStruct.nSales, 1000)).subscribe((currentValue) => {
            this.dashStructAux.nSales = currentValue;
        },
            null,
            () => {
                this.dashStructAux.nSales = this.dashStruct.nSales
                sub3.unsubscribe();
            }
        );

        const sub4 = (await this.animatedCount.createCurrentCount(this.dashStruct.pBRPrice, 1000)).subscribe((currentValue) => {
            this.dashStructAux.pBRPrice = currentValue
        },
            null,
            () => {
                this.dashStructAux.pBRPrice = this.dashStruct.pBRPrice
                sub4.unsubscribe();
            }
        );

        const sub5 = (await this.animatedCount.createCurrentCount(this.dashStruct.aSales, 1000)).subscribe((currentValue) => {
            this.dashStructAux.aSales = currentValue
        },
            null,
            () => {
                this.dashStructAux.aSales = this.dashStruct.aSales
                sub5.unsubscribe();
            }
        );

        const sub6 = (await this.animatedCount.createCurrentCount(this.dashStruct.asTime.days, 1000)).subscribe((currentValue) => {
            this.dashStructAux.asTime.days = currentValue
        },
            null,
            () => {
                this.dashStructAux.asTime.days = this.dashStruct.asTime.days
                sub6.unsubscribe();
            }
        );

        const sub7 = (await this.animatedCount.createCurrentCount(this.dashStruct.asTime.hours, 1000)).subscribe((currentValue) => {
            this.dashStructAux.asTime.hours = currentValue
        },
            null,
            () => {
                this.dashStructAux.asTime.hours = this.dashStruct.asTime.hours
                sub7.unsubscribe();
            }
        );

        const sub8 = (await this.animatedCount.createCurrentCount(this.dashStruct.asTime.minutes, 1000)).subscribe((currentValue) => {
            this.dashStructAux.asTime.minutes = currentValue
        },
            null,
            () => {
                this.dashStructAux.asTime.minutes = this.dashStruct.asTime.minutes
                sub8.unsubscribe();
            }
        );
        this.isAnimated = true;
    }



    async createBarStatusDate() {
        this.colors = [
            '#29a87c',
            '#ff3a67',
            '#f8a72d',
            '#0096d6',
            '#64c2e7',
            '#47CB9A',
            '#152f50',
            '#877f7d',
            '#ff69b4',
            '#00ced1',
            '#dda0dd',
            '#ff6347',
            '#7fff00',
            '#4169e1',
            '#ffd700',
            '#8a2be2',
            '#228b22',
            '#ff4500',
            '#9400d3',
            '#00ff00',
            '#1e90ff',
            '#ff1493',
            '#20b2aa',
            '#ff8c00'
        ];
        //const statusName = this.dashStruct.sBCountry.map(status => status.country_code);
        // const statusValue = this.dashStruct.sBCountry.map(status => status.nSalesByC);
        const statusName: any = [];

        let i: number = 0;
        let j: number = 0;

        this.dashStruct.sBCountry.map((option: any, index: number) => {
            statusName.push(option.country_code);
            const auxt: any = [];
            auxt.push(option.nSalesByC)
            for (j = 0; j < index && i != 0; j++) {
                auxt.unshift('');
            }

            this.dataAux.push({
                type: 'bar',
                label: option.country_code,
                backgroundColor: this.colors[i],
                data: auxt,
            })
            i++;

        });

        this.barDataOptions = {
            labels: statusName,
            datasets: this.dataAux,
        };

        this.barOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                xAxes: {
                    stacked: true,
                    ticks: {
                        maxRotation: 50,

                    },
                },
                yAxes: {
                    stacked: true
                }
            }
        };
    }


}

interface Dashstruct {
    nCostumers: any;
    ncListings: any;
    nSales: any;
    aSales: any;
    tPListed: any;
    tBSProduct: any;
    sBCountry: any;
    asTime: any;
    pBRPrice: any;
}