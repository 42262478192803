<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
    <div class="loading"></div>
</div>


<div *ngIf="!loading">
    <div class="grid m-4" style="justify-content: center !important;">
        <div class="col-12 md:col-4">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.total_customers' | translate }}</h5>
                    </div>
                    <div class="bg-cyan-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-users text-cyan-500 text-xl"></i>
                    </div>
                </div>
                <h2 class="flex justify-content-center mb-3">{{dashStructAux.nCostumers && dashStructAux.nCostumers !== 'NaN' ? 
                    dashStructAux.nCostumers : 0}}</h2>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.number_current_listings' | translate }}</h5>
                    </div>
                    <div class=" border-round flex align-items-center justify-content-center" 
                        style="width: 2.5rem; height: 2.5rem; background-color: #dbdbdb !important;">
                        <i class="pi pi-pencil text-xl" style="color: #404040  !important;"></i>
                    </div>
                </div>
                <h2 *ngIf="!loading" class="counter flex justify-content-center mb-3" >{{dashStructAux.ncListings && dashStructAux.ncListings !== 'NaN' ? 
                    dashStructAux.ncListings : 0}}
                    
                  </h2>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.number_of_sales' | translate }}</h5>
                    </div>
                    <div class="bg-orange-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-wallet text-orange-500 text-xl"></i>
                    </div>
                </div>
                <h2 class="flex justify-content-center mb-3">{{dashStructAux.nSales && dashStructAux.nSales !== 'NaN' ? 
                    dashStructAux.nSales : 0}}</h2>
            </div>
        </div>
    </div>

    <div class="grid m-4" style=" margin-top: -22px !important; justify-content: center !important;">
        <div class="col-12 md:col-4">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.average_selling _time' | translate }}</h5>
                    </div>
                    <div class="bg-pink-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-clock text-pink-500 text-xl"></i>
                    </div>
                </div>
                <div class="flex flex-wrap  justify-content-center mb-3 " style="margin-top: 2rem !important;">
                <h5  *ngIf="dashStructAux.asTime.days!=0 && dashStructAux.asTime.days!=null" class="flex flex-nowrap align-items-end">{{dashStructAux.asTime.days}}<h5 style="font-size: 13px !important;">Days</h5> </h5>
                <h5  *ngIf="dashStructAux.asTime.hours!=0 && dashStructAux.asTime.hours!=null" class="flex flex-nowrap align-items-end">{{dashStructAux.asTime.hours}}<h5 style="font-size: 13px !important;">Hours</h5></h5>
                <h5  *ngIf="dashStructAux.asTime.minutes!=0 && dashStructAux.asTime.minutes!= null" class="flex flex-nowrap align-items-end">{{dashStructAux.asTime.minutes}}<h5 style="font-size: 13px !important;">Minutes</h5></h5>
            </div>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.below_retail_price' | translate }}</h5>
                    </div>
                    <div class="bg-orange-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-angle-double-down text-orange-500 text-xl"></i>
                    </div>
                </div>
                <h2 class="flex justify-content-center mb-3">{{dashStructAux.pBRPrice && dashStructAux.pBRPrice !== 'NaN' ? 
                    dashStructAux.pBRPrice : '-'}}%</h2>
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.total_sales_value' | translate }}</h5>
                    </div>
                    <div class="bg-yellow-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-euro text-yellow-500 text-xl"></i>
                    </div>
                </div>
                <h2 class="flex justify-content-center mb-3">{{dashStructAux.aSales && dashStructAux.aSales !== 'NaN' ? 
                    dashStructAux.aSales : '-'}}€</h2>
            </div>
        </div>

        <div class="col-12 md:col-6">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.top_3_products_listed' | translate }}</h5>
                    </div>
                    <div class="bg-purple-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-shopping-bag text-purple-500 text-xl"></i>
                    </div>
                </div>
                <div >
                    <div *ngIf="dashStruct.tPListed!=null">
                <div *ngFor="let p of  dashStruct.tPListed; let i = index" >
                    <div class="flex flex-wrap justify-content-between mb-3">
                <div>
                <h6 class="flex justify-content-start mb-3">{{p.title}}</h6>
                <h8 class="flex justify-content-start mb-3">SKU: {{p.sku}}</h8>
                <h8 class="flex justify-content-start mb-3">Times Listed: {{p.nProducts}} </h8>
                <h8 class="flex justify-content-start mb-3">Color: {{p.color}}</h8>
                <h8 class="flex justify-content-start mb-3">Size: {{p.size}} </h8>
                </div>
                <img loading="lazy" [src]="p.photo" [alt]="p.title" style="max-height: 180px;"/>
                </div>
                <hr *ngIf="dashStruct.tPListed.length-1!== i" class="mb-3">
                </div>
                </div>

                </div>

            </div>
        </div>

        <div class="col-12 md:col-6">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.2hand.top_3_best-selling_products' | translate }}</h5>
                    </div>
                    <div class="bg-blue-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                    </div>
                </div>
                <div >

                    <div *ngIf="dashStruct.tBSProduct!=null">
                <div *ngFor="let p of  dashStruct.tBSProduct; let i = index" >
                    <div class="flex flex-wrap justify-content-between mb-3">
                <div>
                <h6 class="flex justify-content-start mb-3">{{p.title}}</h6>
                <h8 class="flex justify-content-start mb-3">SKU: {{p.sku}}</h8>
                <h8 class="flex justify-content-start mb-3">Times Sold: {{p.bSProducts}} </h8>
                <h8 class="flex justify-content-start mb-3">Color: {{p.color}}</h8>
                <h8 class="flex justify-content-start mb-3">Size: {{p.size}} </h8>
                <h8 class="flex justify-content-start mb-3">{{p.country_code}} </h8>
                </div>
                <img loading="lazy" [src]="p.photo" [alt]="p.title" style="max-height: 180px;"/>
                </div>
                <hr *ngIf="dashStruct.tPListed.length-1!== i" class="mb-3">
                </div>
            </div>


                </div>
            </div>
        </div>

        <div class="col-12 md:col-12">
            <div class="card-shadow" style="min-height: 300px;">
                <div class="flex justify-content-between mb-3">
                    <h5>{{ 'orders.2hand.sales_by_country' | translate }}</h5>
                    <div class="bg-green-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-globe text-green-500 text-xl"></i>
                        
                    </div>
                </div>
                <div class="flex flex-column align-items-center justify-content-center"
				style="width: 100% !important; height: 100%;">
				<p-chart type="bar" [data]="barDataOptions" height="350px" [style]="{'width': '70%', 'height': '80%'}"
					responsive="true" [options]="barOptions"></p-chart>
			</div>
            </div>
        </div>
    </div>
</div>