import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root',
})
export class SecHandService {
    constructor(private http: HttpClient) { }

    async get2Hands(dateI: any, dateF: any) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                '2hand/product/child/all' // inicial phase without dates
            )
            .toPromise();
        return data;
    }

    async get2HandLines(product_child_id: any) {
        let data = await this.http
            .get<any>(AUTH_API + '2hand/product/child?id=' + product_child_id)
            .toPromise();
        return data;
    }

    async get2HandJournal(product_child_id: any) {
        try {
            let data = await this.http
                .get<any>(AUTH_API + '2hand/product/getHistoryJournal2hand?id=' + product_child_id)
                .toPromise();
            return data;
        }
        catch (error) {
            return [];
        }
    }

    async put2HandLinesStatus(lines: any) {
        console.log(lines)
        let data = await this.http.put<any>(AUTH_API + '2hand/product/child/status', lines).toPromise();
        return data;
    }

    async reject2hand(lines: any) {
        let data = await this.http.put<any>(AUTH_API + '2hand/product/child/status', lines).toPromise();
        return data;
    }

    async get2HandDashboardData(id_brand: number) {
        const data = await this.http.get<any>(AUTH_API + 'dashBoard/all2hand').toPromise();
        return data;
    }
    
    async get2HandDashboardRegisteredCustomers() {

        try {
            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handRegisteredCustomers').toPromise();
            return data;
        } catch (error) {
            console.log(error)
            return null;
        }


    }
    async get2HandDashboardCurrentListings() {
        try {
            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handCurrentListings').toPromise();
            return data;
        }
        catch (error) {
            console.log(error)
            return null;
        }
    }
    async get2HandDashboardNumberSales() {

        try {
            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handNumberSales').toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async get2HandDashboardTotalSales() {
        try {

            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handTotalSales').toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async get2HandDashboardTopListedProducts() {
        try {
            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handTopListedProducts').toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async get2HandDashboardTopBestSellingProducts() {
        try {
            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handTopBestSellingProducts').toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async get2HandDashboardSalesByCountry() {
        try {


            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handSalesByCountry').toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async get2HandDashboardAverageSellingTime() {
        try {

            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handAverageSellingTime').toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async get2HandDashboardPBelowRetailPrice() {
        try {

            const data = await this.http.get<any>(AUTH_API + 'dashBoard/2handPBelowRetailPrice').toPromise();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async getLinkProductOnlineStore(subID:any){
        const data = await this.http.get<any>(AUTH_API + '2hand/product/getLinkStore?id_order='+ subID).toPromise();
        return data;
    }

    async putCloudinaryImage(imageData:any){
        let data = await this.http.put<any>(AUTH_API + '2hand/product/cloudinary/update', imageData).toPromise();
        return data;

    }

    
}
