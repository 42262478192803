import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { AppMainComponent } from 'src/app/app.main.component';
import { BrandService } from 'src/app/services/brands/brand.service';
import { SecondHandOrdersService } from 'src/app/services/2hand_orders/SecondHandOrdersService.service';
import * as JSZip from 'jszip';


@Component({
    selector: 'pages-2hand_orders',
    templateUrl: './2hand_orders.component.html',
    styleUrls: ['2hand_orders.component.scss'],
})
export class SecondHandOrdersComponent implements OnInit {

    pipe = new DatePipe('en-US');

    rangeDates: Date[] = [
        new Date(new Date().setDate(new Date().getDate() - 31)),
        new Date(),
    ];

    dateIAux: string;

    dateFAux: string;

    all2HandOrders: any = [];

    secondHandOrdersAux: any;

    secondHandOrders: any;

    secondHand: any;

    loading: boolean = true;

    loadingSidebar: boolean = false;

    ordersStatus: any;

    statusOptions: any = [];

    idBrand: any;

    statusReturn: any;

    selectedSecondHand: any[] = [];

    selectSecondHand: any;

    visibleSidebar: boolean = false;

    cols: any = [];

    colsOptional: any = [];

    selectedColumns: any = [];

    _selectedColumnsAux: any = [];

    editTrackingDialog: boolean = false;

    show_IBAN: boolean = false;

    subtotal: string = '0';

    processing_fee: number = 0;

    refund_total: string = '0';

    line_images: any = [];

    order_items: any = [];

    displayLabel = false;

    labelUpload = []

    menuShow: boolean = false;

    items = [
        { label: 'Update', icon: 'pi pi-refresh' },
        { label: 'Delete', icon: 'pi pi-times' },
        { label: 'Angular.io', icon: 'pi pi-info' },
        { separator: true },
        { label: 'Setup', icon: 'pi pi-cog' }
    ];


    @ViewChild('dtreturns') table: Table;

    @ViewChild('filter') filter: ElementRef;


    constructor(
        private app: AppMainComponent,
        private readonly translateService: TranslateService,
        private messageService: MessageService,
        private secondHandOrdersService: SecondHandOrdersService,
        private brandService: BrandService,
    ) { }

    copiedToclipboard() {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Copied to clipboard.' });
    }
    
    async init2HandOrders() {

        this.loading = true
        this.cols = [
            { field: 'idInterno', header: 'Subm. ID', sort: true, filter: false }
        ]

        this.colsOptional = [
            { field: 'customer', header: 'Customer', sort: true, filter: false },
            { field: 'country', header: 'Country', sort: true, filter: false },
            { field: 'items', header: 'Items', sort: true, filter: false },
            { field: 'refund_total', header: 'Total', sort: true, filter: false },
            { field: 'dataCriacao', header: 'Order Date', sort: true, filter: false },
            { field: 'orderStatus', header: 'Status', sort: true, filter: false },
            { field: 'deliveryStatus', header: 'Delivery Status', sort: true, filter: false }
        ];
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)

        this.idBrand = this.brandService.getBrand();

        await this.secondHandOrdersService.get2HandOrderStatus().then((status) => {
            this.ordersStatus = status;
            for (let orderStatus of status) {
                this.statusOptions.push({
                    label: orderStatus.description,
                    value: orderStatus.description,
                });
            }
        });

        const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
        let dateF;
        if (this.rangeDates[1] == null) {
            dateF = dateI;
        } else {
            dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
        }

        //if (dateI != this.dateIAux || dateF != this.dateFAux || refresh) {
        await this.secondHandOrdersService
            .getReturns(this.idBrand, dateI, dateF)
            .then((secondHandOrders) => {
                this.secondHandOrdersAux = [];
                /*var paymentOptions = Array.from(new Set(secondHandOrders.map(({ payment_description }) => payment_description)));
                paymentOptions.map(payment => {
                    this.paymentOptions.push({ value: payment, label: payment })
                })*/
                secondHandOrders.map((ret) => {

                    const orderReturn: any = {
                        idInterno: ret.id_order_header,
                        IDStatus: ret.id_order_status,
                        orderStatus: this.ordersStatus.find((r: any) => r.id_order_status == ret.id_order_status).description,
                        items: ret.order_lines_count,
                        customer: ret.email,
                        country: ret.country_code,
                        returnPaymentMethod: ret.description,
                        refund_total: Number(ret.amount),
                        currency: ret.currency,
                        name: ret.first_name + ' ' + ret.last_name,
                        //dataCriacao: this.app.getDateOnFormatString(ret.created_at)
                        dataCriacao: ret.created_at,
                        deliveryStatus: ret.delivered == 1 ? "Delivered" : "Not delivered"
                    };
                    this.secondHandOrdersAux.push(orderReturn);
                });
                var sortingArr = [1, 49, 53, 4, 52, 0, 55, 13, 51, 9, 99, 98, 16]
                this.secondHandOrders = this.secondHandOrdersAux.sort(
                    (a, b) =>
                        sortingArr.indexOf(a.IDStatus) - sortingArr.indexOf(b.IDStatus) ||
                        Number(b.idInterno) - Number(a.idInterno)
                );
            });
        //}
        this.dateIAux = dateI;
        this.dateFAux = dateF;
        this.loading = false;

        const orderStatusFields =
            document.getElementsByClassName('order-status');
        const arr = Array.from(orderStatusFields);
        arr.map((orderStatusField) => {
            orderStatusField.removeAttribute('disabled');
        });

    }

    async ngOnInit() {
        await this.init2HandOrders();
    }

    filterTable(e) {
        var orderStatus = this.statusReturn[e['element'].index];
        var returnsFilters = this.all2HandOrders.filter((e) => e.orderStatus == orderStatus);
        function arrayEquals(a, b) {
            return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index]);
        }

        var filterEquals = arrayEquals(this.secondHandOrders, returnsFilters);
        if (filterEquals) {
            this.secondHandOrders = this.secondHandOrdersAux
        } else {
            this.secondHandOrders = returnsFilters
        }
    }

    async openMarketPlace() {
        const link_2hand = window.sessionStorage.getItem("2hand_link");
        window.open(link_2hand, '_blank').focus();
    }

    async refreshReturns() {
        await this.ngOnInit();
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
    }


    openOverlay(event, op) {
        event.stopPropagation();
        op.toggle(event)
    }

    showOverlay() {
        this.app.overlayActive = true
    }

    hideOverlay() {
        this.app.overlayActive = false
    }

    @Input() get selectedColumnsAux(): any[] {
        return this._selectedColumnsAux;
    }

    set selectedColumnsAux(val: any[]) {
        //restore original order
        this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
    }

    customize(event, op) {
        this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
        this.app.overlayActive = false
        op.hide(event)
    }

    revert(event, op) {
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)
        this.app.overlayActive = false
        op.hide(event)
    }

    async openSideBar(selectSecondHand) {
        this.menuShow = false
        this.secondHand = null
        this.visibleSidebar = true
        this.selectSecondHand = selectSecondHand
        document.getElementsByTagName('body')[0].classList.add('fixed')
        await this.secondHandOrderLines(selectSecondHand.idInterno)
        /* var linesStatus = this.secondHand.lines.map(line => line.id_order_status)
         this.itemsApprovedCount = linesStatus.filter(status => [2, 50, 51, 52, 53, 54, 55, 57].includes(status)).length
         this.itemsRejectedCount = linesStatus.filter(status => [1, 49, 56].includes(status)).length*/
    }

    hideSideBar() {
        this.visibleSidebar = false;
        document.getElementsByTagName('body')[0].classList.remove('fixed');
    }

    openMenu(event) {
        event.stopPropagation()
        this.menuShow = !this.menuShow
    }

    labels:any=[];

    async secondHandOrderLines(id_return: any) {
        this.labels = await this.secondHandOrdersService.get2handOrderLabel(id_return);
        this.show_IBAN = false;
        await this.secondHandOrdersService.getReturnLines(id_return).then(
            async (returnline) => {
                this.secondHand = {};
                this.secondHand = returnline.data[0];
                this.secondHand.created_at = this.app.getDateOnFormatString(this.secondHand.created_at);
                this.CalculateTotals();

            }
        ).catch((error) => {
            console.log(error)
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: error.error,
            });
        });
        
    }

    dateTimeLine(event: any): string {
        return this.app.getDateOnFormatString(event.change_timestamp);
    }

    CalculateTotals() {
        this.subtotal = '0';
        this.refund_total = '0';


        for (let line of this.secondHand.lines) {

            this.subtotal = (Number(this.subtotal) + Number(line.secondHandPrice)).toFixed(2);
        }
        this.secondHand.fee_total = Number(this.secondHand.processing_fee)

        // calculate refund total
        this.refund_total = Math.max(
            (Number(this.subtotal) +
                (Number(this.secondHand.shipping_cost)) + (this.secondHand.fee_total)
            ),
            0
        ).toFixed(2);


    }

    /*options_disable() {
        // options disability
        const received: any = [0, /*9,*//* 13, 15, 17, 51, 55, 57];
    /*const refunded: any = [13, 15, 51, 55];
    const resendable: any = [2, 3, 50];
    const notApproved: any = [1, 49];
    const cancelledOrArchived: any = [98, 99];
    const rejected: any = [16, 56];
    const errorWMS: any = [10, 54];
    const approved_received: any = [13, 51];

    for (let option of this.dropdown_options) {
        if (option.id == "history_") continue;
        if (this.secondHand.IDStatus == 9) {
            option.disabled = true;
            option.visible = false;
            continue;
        }
        option.disabled = false;
        option.visible = true;
        if (option.id == 'editreturn') {
            option.disabled = true;
        }
        if (resendable.includes(this.secondHand.IDStatus) || notApproved.includes(this.secondHand.IDStatus) || rejected.includes(this.secondHand.IDStatus)) {
            if (option.id == 'wms' || option.id == 'label' || option.id == 'resend_email' || option.id == 'receive') {
                option.disabled = true;
                continue;
            }
        } else if (errorWMS.includes(this.secondHand.IDStatus)) {
            if (option.id == 'resend_email' || option.id == 'editreturn' || option.id == 'receive') {
                option.disabled = true;
                continue;
            }
        } else if (approved_received.includes(this.secondHand.IDStatus)) {
            if (option.id == 'wms' || option.id == 'resend_email' || option.id == 'editreturn' || option.id == 'receive') {
                option.disabled = true;
                continue;
            }
        } else {
            if (option.id == 'wms') {
                option.disabled = true;
                continue;
            }
        }

        if (this.secondHand.exchange_flag == 1) {
            if (option.id == 'refunded') {
                option.disabled = true;
                continue;
            }
        } else {
            if (!refunded.includes(this.secondHand.IDStatus)) {
                if (option.id == 'refunded') {
                    option.disabled = true;
                    continue;
                }
            }
        }

        if (window.sessionStorage.getItem("wms") == "false") {
            if (option.id == 'wms') {
                option.visible = false;
                continue;
            }
        }

        if (cancelledOrArchived.includes(this.secondHand.IDStatus)) {
            option.disabled = true
            if (option.id == "label") {
                option.disabled = false
            }
            if (option.id == 'cancelReturn' || option.id == 'archiveReturn') {
                option.visible = false
            }
        }

        if (received.includes(this.secondHand.IDStatus)) {
            if (option.id == 'cancelReturn' || option.id == 'archiveReturn') {
                option.visible = false;
                continue;
            }
        }
    }
}*/


    onUpload(event) {
        document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none';
        if (document.getElementsByClassName('p-fileupload-files').length > 0) {
            document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'block';
        }
        for (let file of event.files) {
            this.labelUpload.push(file);
        }
        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
    }

    openDialogLabel() {
        this.displayLabel = true;
        setTimeout(() => {
            if (document.getElementsByClassName('p-fileupload-files').length > 0) {
                document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'none';
            }
        }, 100);
    }

    onRemoveFile() {
        this.labelUpload.pop();
        document.getElementsByClassName('p-fileupload') ? document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none' : null;
    }




    checkboxClick(e) {
        e.stopPropagation()
        var x = document.getElementsByClassName('p-checkbox-box')
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedSecondHand.length > 0) {
            x[0].classList.add('p-highlight')
            y[0].classList.add('pi')
            y[0].classList.add('pi-minus')
        } else {
            x[0].classList.remove('p-highlight')
            y[0].classList.remove('pi')
            y[0].classList.remove('pi-minus')
        }
    }

    mainCheckboxClick() {
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedSecondHand.length == this.secondHandOrdersAux.length) {
            y[0].classList.remove('pi-minus')
        }
    }

    async exportCSV() {
        var selectionAux = this.table._selection
        if (this.table._selection.length == 0) {
            if (this.table.filteredValue) {
                this.table._selection = this.table.filteredValue
            } else {
                this.table._selection = this.table._value
            }
        }
        await this.table.exportCSV({ selectionOnly: true })
        this.table._selection = selectionAux
    }

    set_clipboard() {
        navigator.clipboard.writeText(this.secondHand.mb_return_payment);
        this.messageService.add({ key: 'tst', severity: 'info', summary: "Info", detail: "Copied to CLipboard!" });
    }

    requestLabel(order_name: any) {
       
        if (this.labels.data.length == 1) {
            const source = `data:application/pdf;base64,${this.labels.data[0].label}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = `${order_name}_label.pdf`;
            link.click();
        }
        else if (this.labels.data.length > 1) {
            const zip = new JSZip();

            this.labels.data.forEach((label, index) => {
                const source = `${label.label}`;
                const fileName = `${order_name}_label_${index + 1}.pdf`;
                zip.file(fileName, source, { base64: true });
            });

            zip.generateAsync({ type: 'blob' }).then((content) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(content);
                link.download = `${order_name}_labels.zip`;
                link.click();
            });

        }
    }

    async sentToBuyer(id_order){
        let request_data = {
            id_order: id_order,
        };
        await this.secondHandOrdersService.post2handOrderSentToBuyer(request_data).then(
            async (data) => {
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: 'Success'});
                this.hideSideBar();
            },
            (error) => {
                this.loading = false;
                this.messageService.add({
                    key: 'tst',
                    severity: 'warn',
                    summary: this.translateService.instant('general.error'),
                    detail: 'Error'
                });
            }
        )
    }
    
}

