import { NgModule } from '@angular/core';
import { Dash2HandComponent } from "./dash-2hand.component";
import { CommonModule } from '@angular/common';
import { ChartModule } from 'primeng/chart';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ChartModule,
        TranslateModule
    ],
    declarations: [
        Dash2HandComponent
    ],
    exports: [
        Dash2HandComponent
    ],

})
export class Dash2HandModule { }